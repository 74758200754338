import { REDIRECT_TYPE_TEMPORARY } from '@/model/ValueObject/RedirectTypes'

export default {
  siteId: '',
  createdById: null,
  createdAt: null,
  modifiedById: null,
  modifiedAt: null,
  redirectType: REDIRECT_TYPE_TEMPORARY,
  originalUrl: '',
  alias: '',
  validFrom: null,
  reservedUntil: null,
  description: '',
  isReadOnly: false,
  deleted: false,
  isAutoGenerated: false,
  isArticle: false,
  isAuthor: false,
  isGeneeaTag: false,
  isRubric: false,
  isTopic: false,
  audits: []
}
