import { i18n } from '@/main'
export const REDIRECT_TYPE_PERMANENT = 'Permanent'
export const REDIRECT_TYPE_TEMPORARY = 'Temporary'

export default [
  {
    id: REDIRECT_TYPE_PERMANENT,
    title: i18n.t('redirectVlm.redirectTypes.permanent')
  },
  {
    id: REDIRECT_TYPE_TEMPORARY,
    title: i18n.t('redirectVlm.redirectTypes.temporary')
  }
]
