<template>
  <span
    :class="`redirect-status__badge redirect-status__badge--${status}`"
  >
    {{ $t(`redirectVlm.statuses.${status}`) }}
  </span>
</template>
<script>

import RedirectHelperServiceVlm from '@/services/redirect/RedirectHelperServiceVlm'

export default {
  name: 'RedirectStatusVlm',
  props: {
    redirect: {
      type: Object,
      required: true
    }
  },
  computed: {
    status () {
      if (!this.redirect.id) {
        return 'new'
      }
      if (this.redirect.deleted) {
        return 'deleted'
      }
      if (RedirectHelperServiceVlm.isRedirectActive(this.redirect)) {
        return 'active'
      }
      return 'inactive'
    }
  }
}
</script>

<style lang="scss" scoped>
.redirect-status {
  display: inline-block;
  &__badge {
    font-family: "Roboto", sans-serif;
    font-size: rem(12px);
    display: inline-block;
    text-transform: uppercase;
    font-weight: 500;
    padding: rem(8px) rem(11px);
    border-radius: rem(4px);
    &--new {
      color: #8A96AC;
      background: #D6D6D6;
    }
    &--active {
      color: #5AB963;
      background: #ECF7ED;
    }
    &--inactive {
      color: #FFA200;
      background: #FFF3E0;
    }
    &--deleted {
      color: #F57BA3;
      background: #FFEBEE;
    }
  }
}
</style>
