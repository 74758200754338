import moment from 'moment'
import { i18n } from '@/main'
import { REDIRECT_TYPE_PERMANENT } from '@/model/ValueObject/RedirectTypes'

const getAliasType = (redirect) => {
  const aliasType = i18n.t(`redirectVlm.aliasTypes.${redirect.isAutoGenerated ? 'redirect' : 'alias'}`)
  if (redirect.isArticle) {
    return `${aliasType} (Article Editor)`
  }
  if (redirect.isAuthor) {
    return `${aliasType} (Author)`
  }
  if (redirect.isGeneeaTag) {
    return `${aliasType} (Geneea Tag)`
  }
  if (redirect.isRubric) {
    return `${aliasType} (Rubric)`
  }
  if (redirect.isTopic) {
    return `${aliasType} (Topic)`
  }
  return aliasType
}

const isRedirectActive = (redirect) => {
  const { redirectType, validFrom, reservedUntil, id } = redirect
  return id &&
    (
      moment().isAfter(validFrom) &&
      (
        redirectType === REDIRECT_TYPE_PERMANENT ||
        (!reservedUntil || moment().isBefore(reservedUntil))
      )
    )
}

const isRedirectReadOnly = (redirect) => {
  return redirect.isReadOnly ||
    redirect.isAutoGenerated ||
    redirect.deleted ||
    isRedirectActive(redirect)
}

export default {
  getAliasType,
  isRedirectActive,
  isRedirectReadOnly
}
